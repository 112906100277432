<template>
  <b-container class="mt-3" :class="$router.name == 'payment.split' ? 'px-0' : ''">
    <b-row class="justify-content-center">
      <b-col :class="$router.name == 'payment.split' ? 'px-0' : ''" :cols="column">
        <b-form>
          <b-row>
            <!-- Atas Nama -->
            <b-col cols="12" class="mb-2">
              <b-form-group class="custom__form--input mb-0" label="Atas Nama" label-for="atas-nama">
                <v-select v-model="formPayload.payment_customer_uuid" label="title" :options="options"
                  :reduce="(option) => option.label" @search="onSearch" :disabled="$route.name === 'payment.split'" />
              </b-form-group>
            </b-col>

            <!-- Saldo -->
            <b-col cols="8" class="mb-2">
              <b-form-group class="custom__form--input mb-0" label="Saldo Deposit" label-for="saldo-deposit">
                <money id="saldo-deposit" v-model="saldo" v-bind="money" class="custom__input pl-1 w-100"
                  :class="saldo == 0 ? 'bg-danger' : ''" placeholder="x-xxxx-xxxx" readonly />
                <small v-if="saldo == 0" class="text-dark text-darken-5 fw-bold-600">Saldo Anda Tidak Cukup</small>
              </b-form-group>
            </b-col>
            <b-col cols="4" class="mb-2 d-flex" :class="saldo == 0 ? 'align-items-center' : 'align-items-end'">
              <b-form-group class="custom__form--input mb-0 w-100" label=" " label-for="top-up">
                <b-button class="bg-white btn-block py-1" style="padding: 1.3rem 0 !important"
                  @click="$bvModal.show('modal-deposit')">
                  Top-up
                </b-button>
              </b-form-group>
            </b-col>

            <!-- Masukan Nominal -->
            <b-col v-if="$route.name == 'pos.index'" cols="12" class="mb-2">
              <b-form-group class="custom__form--input mb-0">
                <label for="">Masukan Nominal <span class="text-danger">*</span></label>
                <input :id="`amount-deposit-${indexNominal}`" class="focus-0 custom__input pl-1 w-100" type="text"
                  :value="formPayload.cash_total_payment" @input="getAmountDeposit" @change="getAmountDeposit"
                  required />
              </b-form-group>
            </b-col>

            <!-- Button -->
            <b-col v-if="!isButtonHide" cols="12" class="mb-2 mt-2">
              <b-button class="btn-block size16 py-1 border-8" @click="
                isSubmit ? submit() : $store.commit('formWizard/setStep', 2)
                ">
                Selanjutnya
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <ModalTopup :results="results" :get-data="getCustomer" />
  </b-container>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import {
  BContainer,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from "bootstrap-vue";
import { formatRupiah } from "@/auth/utils";
import vSelect from "vue-select";
import ModalTopup from "@/components/Deposit/Modal/ModalTopup.vue";
import { integer } from "vee-validate/dist/rules";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ModalTopup,
    vSelect,
  },
  props: {
    isSubmit: {
      type: Boolean,
    },
    isButtonHide: {
      type: Boolean,
      default: false,
    },
    submit: {
      type: Function,
    },
    column: {
      type: Number,
      default: 5,
    },
    formData: {
      type: Object,
    },
    totalNominal: {
      type: Number,
    },
    totalAddtionalCost: {
      type: Number,
    },
    indexNominal: {
      type: Number,
    },
  },
  setup() {
    return {
      formatRupiah,
    };
  },
  data() {
    return {
      saldo: 0,
      results: {},
      money: {
        decimal: "",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
      },
      options: [],
      formPayload: {
        payment_customer_uuid: "",
        cash_total_payment: "",
      },
    };
  },
  watch: {
    formData: {
      handler(value) {
        this.formPayload = value
        setTimeout(() => {
          this.getAmountDeposit()
        }, 300);
      },
      deep: true,
    },
    // localStorage.payment_customer_uuid
    formPayload: {
      handler(value) {
        const payload = {
          ...value,
        };
        this.$emit("payloadDeposit", payload);
      },
      deep: true,
    },
    "formPayload.payment_customer_uuid": function (value) {
      this.getDetail(value);
    },
    "$store.state.customer.updateData": function (value) {
      this.getDetail(localStorage.getItem("customer_uuid"));
    },
  },
  created() {
    this.getCustomer();
  },
  mounted() {
    this.formPayload.payment_customer_uuid = localStorage.getItem('customer_uuid')
    const transaction_query = JSON.parse(
      localStorage.getItem("transaction_query")
    );

    if (transaction_query.data) {
      const decodeData = transaction_query.data
      setTimeout(() => {
        for (let index = 0; index < decodeData.length; index++) {
          const element = decodeData[index];
          if (
            this.indexNominal === index &&
            element.payment_method == "wallet"
          ) {
            this.formPayload = {
              ...element,
            };
          }
        }
      }, 500);
    }

    // Re assign cash total payment on same index
    this.formPayload.cash_total_payment = this.formData.cash_total_payment

    // if (transaction_query.total) {
    //   setTimeout(() => {
    //     this.formPayload.cash_total_payment = transaction_query.total || '';
    //     setTimeout(() => {
    //       this.getAmountDeposit();
    //     }, 200);
    //   }, 300);
    // }
  },
  methods: {
    formatCurrency(value) {
      if (isNaN(value)) {
        return "";
      }
      return value.toLocaleString("id-ID");
    },
    getAmountDeposit() {
      const rupiah = document.getElementById(
        `amount-deposit-${this.indexNominal}`
      );
      rupiah.value = this.formatRupiah(rupiah.value, "");
      this.formPayload.cash_total_payment = rupiah.value;
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.searchItem(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.options = [];
          this.getCustomer();
          loading(false);
        }, 300);
      }
    },
    searchItem: _.debounce((loading, search, vm) => {
      vm.$store
        .dispatch("customer/getData", {
          params: {
            search: escape(search),
          },
        })
        .then((response) => {
          vm.options = [];
          response.data.data.data.forEach((item) => {
            vm.options.push({
              label: item.uuid,
              title: item.name,
            });
          });
          const option = vm.getUniqueListBy(vm.options, "label");
          vm.options = option;
          loading(false);
        });
    }, 300),
    async getDetail(uuid) {
      this.$store.commit("customer/setUpdateData", false);
      localStorage.setItem("payment_customer_uuid", uuid);
      this.$store
        .dispatch("customer/getDetail", {
          uuid,
        })
        .then((result) => {
          this.results = result.data.data;
          // this.options.unshift({
          //   label: result.data.data.uuid,
          //   title: result.data.data.name,
          // })
          this.saldo = result.data.data.wallet_balance;
          setTimeout(() => {
            const option = this.getUniqueListBy(this.options, "label");
            this.options = option;
          }, 500);
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async getCustomer() {
      this.options = [];
      this.isLoading = true;
      this.$store
        .dispatch("customer/getData", {
          params: {
            per_page: 30,
          },
        })
        .then((result) => {
          result.data.data.data.forEach((item) => {
            this.options.push({
              label: item.uuid,
              title: item.name,
            });
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    // getData() {
    //   this.$store.dispatch('customer/getDetail', {
    //     uuid: localStorage.customer_uuid || '',
    //   }).then(result => {
    //     this.saldo = result.data.data.wallet_balance
    //     this.results = result.data.data
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
@import "@/assets/scss/variables/_variables.scss";

.bg-danger {
  background-color: $danger-1 !important;
  border: 1px solid $danger-2 !important;
  color: $dark !important;
  border-radius: 8px;
}
</style>
